git/* eslint-disable prettier/prettier */
<template>
  <div>
    <input style="display: none;" @change="uploadFile" type="file" ref="fileInput"/>
    <div>
      <div>
        <b-row class="header-row">
          <b-col cols="3" class="pl-0" v-if="formStatus.profile">
            <!-- @click="activeTab = 'profil'" -->
            <b-button v-if="vProfile || activeTab == 'profil'" id="tabs" variant="primary" block >
              <div v-if="vProfile" class="check-tab"><v-icon style="color:#339933;">mdi-check-circle</v-icon></div>
              Profil
            </b-button>
            <b-button id="tabs" v-else variant="outline-secondary" style="border: 1px solid rgba(0, 0, 0, 0.2);" block>
              <span style="color: #053A69;font-weight: bold;">Profile</span>
            </b-button>
          </b-col>
          <b-col cols="3" v-if="formStatus.structure">
            <!-- @click="activeTab = 'struktur'" -->
            <b-button id="tabs" v-if="vStruktur || activeTab == 'struktur'" variant="primary" block >
              <div v-if="vStruktur" class="check-tab"><v-icon style="color:#339933;">mdi-check-circle</v-icon></div>
              Struktur Organisasi
            </b-button>
            <!-- @click="activeTab = 'struktur'" -->
            <b-button id="tabs" v-else variant="outline-secondary" style="border: 1px solid rgba(0, 0, 0, 0.2);" block>
              <span style="color: #053A69;font-weight: bold;">Struktur Organisasi</span>
            </b-button>
          </b-col>
          <b-col cols="3" v-if="formStatus.rekening">
            <!-- @click="activeTab = 'rekening'" -->
            <b-button id="tabs" v-if="vRekening || activeTab == 'rekening'" variant="primary" block>
              <div v-if="vRekening" class="check-tab"><v-icon style="color:#339933;">mdi-check-circle</v-icon></div>
              Rekening
            </b-button>
            <b-button id="tabs" v-else variant="outline-secondary" style="border: 1px solid rgba(0, 0, 0, 0.2);" block>
              <span style="color: #053A69;font-weight: bold;">Rekening</span>
            </b-button>
          </b-col>
          <!-- @click="activeTab = 'cabang'" -->
          <b-col cols="3" class="pr-0" v-if="formStatus.cabang">
            <b-button id="tabs" v-if="vCabang || activeTab == 'cabang'" variant="primary" block>
              <div v-if="vCabang" class="check-tab"><v-icon style="color:#339933;">mdi-check-circle</v-icon></div>
              Cabang Ormas
            </b-button>
            <b-button id="tabs" v-else variant="outline-secondary" style="border: 1px solid rgba(0, 0, 0, 0.2);" block>
              <span style="color: #053A69;font-weight: bold;">Cabang Ormas</span>
            </b-button>
          </b-col>
        </b-row>


        <div id="tab-form" pills disabled card justified class="shadow" style="border-radius:10px;">

          <div v-if="formStatus.profile && activeTab == 'profil'" active title="Profil" style="background-color:white;margin-top:0px; padding:32px;">
            <b-row>
              <b-col>
                <span style="font-size:24px;font-weight:bold;">{{namaOrmasTitle}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-row class="pt-3 pb-3 d-flex align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Unggah Logo Organisasi" }}</p></b-col
                  >:<b-col sm="2">
                    
                    <b-button v-if="file_logo == ''" variant="primary" size="sm" @click="fileClicked('logo')"><v-icon>mdi-upload</v-icon> Unggah</b-button>
                    <!-- <a :href="fileURL + file_logo" target="_blank" type="button">Lihat File</a> -->
                    <a v-if="file_logo != ''" :href="fileURL + file_logo" target="_blank" type="button">
                    <b-button variant="success" size="sm">
                      Lihat File
                    </b-button>
                    </a>
                    <b-button v-if="file_logo != ''" @click="file_logo = ''" variant="danger" size="sm" class="ml-3">
                      <v-icon class="p-0">mdi-delete-outline</v-icon>
                    </b-button>
                  </b-col>
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Unggah Bendera Organisasi" }}</p></b-col
                  >:<b-col sm="3">
                    <b-button v-if="file_bendera == ''" variant="primary" size="sm" @click="fileClicked('bendera')"><v-icon>mdi-upload</v-icon> Unggah</b-button>
                    <!-- <a :href="fileURL + file_logo" target="_blank" type="button">Lihat File</a> -->
                    <a v-if="file_bendera != ''" :href="fileURL + file_bendera" target="_blank" type="button">
                    <b-button variant="success" size="sm">
                      Lihat File
                    </b-button>
                    </a>
                    <b-button v-if="file_bendera != ''" @click="file_bendera = ''" variant="danger" size="sm" class="ml-3">
                      <v-icon class="p-0">mdi-delete-outline</v-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <!-- <span class="text-danger">*</span> -->
                  <b-col sm="3"
                  
                    ><p style="font-weight: 600; margin:0px;">{{ "Email" }}</p></b-col
                  >:<b-col sm="6">
                    <b-form-input v-model="formProfil.email" :state="validateState('email')" placeholder="Silahkan masukkan email">{{$v.formProfil.email.$model}}</b-form-input>
                    
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Nomor Telepon" }}</p></b-col
                  >:<b-col sm="6">
                    <b-form-input v-model="formProfil.no_tlp" placeholder="Silahkan masukkan nomor telepon"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Nomor FAX" }}</p></b-col
                  >:<b-col sm="6">
                    <b-form-input v-model="formProfil.no_fax" placeholder="Silahkan masukkan nomor fax"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Ciri khusus" }}</p></b-col
                  >:<b-col sm="6">
                    <b-form-select v-model="formProfil.m_ciri_khusus_id">
                      <b-form-select-option value="" disabled>Silahkan masukkan Ciri Khusus</b-form-select-option>
                      <b-form-select-option :value="item.id" v-for="(item,index) in listCiriKhusus" :key="index">{{item.namaCiriKhusus}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Wadah Berhimpun" }}</p></b-col
                  >:<b-col sm="6">
                    <b-form-select style="max-width: 478px;" v-model="formProfil.wadahBerhimpun" >
                      <b-form-select-option value="" disabled>Silahkan masukkan Wadah Berhimpun</b-form-select-option>
                      <b-form-select-option :value="item.id" v-for="(item,index) in listBadanOtonom" :key="index">{{item.nama_ormas}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
                 <b-row :class="formProfil.badan_otonom.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Badan Otonom / Sayap Organisasi" }}</p></b-col
                  >:<b-col sm="8">
                    <b-row v-for="(i,index) in formProfil.badan_otonom" :key="index" class="pb-2">
                      <b-col>
                          <b-row>
                            <b-col cols="9" class="pr-0">
                              <b-form-select style="max-width: 478px;" v-model="formProfil.badan_otonom[index].id" >
                                <b-form-select-option value="" disabled>Silahkan masukkan Badan Otonom / Sayap Organisasi</b-form-select-option>
                                <b-form-select-option :value="item.id" v-for="(item,index) in listBadanOtonom" :key="index">{{item.nama_ormas}}</b-form-select-option>
                              </b-form-select>
                            </b-col>
                            <b-col cols="3" class="d-flex align-items-center pl-0">
                              <div class="button-minus" v-if="index != 0 && formProfil.badan_otonom.length - index == 1"><v-icon style="color:white;" small @click="formProfil.badan_otonom.splice(-1)">mdi-minus</v-icon></div>
                              <div class="button-plus" v-if="formProfil.badan_otonom.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                              <div class="button-plus" v-if="formProfil.badan_otonom.length - index == 1"><v-icon style="color:white;" small @click="formProfil.badan_otonom.push({id:''})">mdi-plus</v-icon></div>
                            </b-col>
                          </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col class="d-flex justify-content-center pt-5 mt-5">
                <button
                  class="mt-16 text-center button-back mr-5"
                  @click="$router.push('/permohonan')"
                >
                  Batal
                </button>
                <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="navFwdAct('profil')">
                  {{ navFwd('profil')}}
                </b-button>
              </b-col>
            </b-row>
          </div>

          <div v-if="formStatus.structure && activeTab == 'struktur'" title="Struktur Organisasi" style="background-color:white;margin-top:0px;padding:32px;">

            <b-card-text class="pl-0 pr-0">
              <div class="card-body pt-0 pb-3 pl-0 pr-0">
                <div class="tab-content">
                  <b-row>
                    <b-col class="d-flex justify-content-between">
                      <span style="font-size:24px;font-weight:bold;">{{namaOrmasTitle}}</span>
                      <b-button variant="primary" @click="$bvModal.show('modal-form-struktur')" size="sm"><v-icon dark>mdi-pencil</v-icon> Ubah</b-button>
                    </b-col>
                  </b-row>
                  <div class="mt-5">
                    <section v-if="strukturEdit.pendiri_list.length > 0 && strukturEdit.pendiri_list[0].namaPengurus != ''">
                      <b-row class="pt-3 pb-3 d-flex align-items-center" >
                        <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Daftar Pendiri Organisasi</p></b-col
                        >:<b-col sm="8">
                          <b-row class="pb-2">
                            <b-col cols="10" class="pr-0 d-flex">
                              <div class="repeat-div">
                                <span v-for="(item,index) in strukturEdit.pendiri_list" :key="index + 'k'">
                                  {{item.namaPengurus}};&nbsp;
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                      <b-row class="pt-3 pb-3 d-flex align-items-center">
                        <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">NIK Daftar Pendiri Organisasi</p></b-col
                        >:<b-col sm="8">
                          <b-row class="pb-2">
                            <b-col cols="10" class="pr-0 d-flex">
                              <div class="repeat-div">
                                <span v-for="(item,index) in strukturEdit.pendiri_list" :key="index + 'k'">
                                  {{item.identitas}};&nbsp;
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </section>

                    <section v-if="strukturEdit.pembina_list.length > 0 && strukturEdit.pembina_list[0].namaPengurus != ''">
                      <b-row class="pt-3 pb-3 d-flex align-items-center" >
                        <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Daftar Pembina Organisasi</p></b-col
                        >:<b-col sm="8">
                          <b-row class="pb-2">
                            <b-col cols="10" class="pr-0 d-flex">
                              <div class="repeat-div">
                                <span v-for="(item,index) in strukturEdit.pembina_list" :key="index + 'k'">
                                  {{item.namaPengurus}};&nbsp;
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                      <b-row class="pt-3 pb-3 d-flex align-items-center">
                        <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">NIK Daftar Pembina Organisasi</p></b-col
                        >:<b-col sm="8">
                          <b-row class="pb-2">
                            <b-col cols="10" class="pr-0 d-flex">
                              <div class="repeat-div">
                                <span v-for="(item,index) in strukturEdit.pembina_list" :key="index + 'k'">
                                  {{item.identitas}};&nbsp;
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </section>

                    <section v-if="strukturEdit.penasehat_list.length > 0 && strukturEdit.penasehat_list[0].namaPengurus != ''">
                      <b-row class="pt-3 pb-3 d-flex align-items-center" >
                        <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Daftar Penasehat Organisasi</p></b-col
                        >:<b-col sm="8">
                          <b-row class="pb-2">
                            <b-col cols="10" class="pr-0 d-flex">
                              <div class="repeat-div">
                                <span v-for="(item,index) in strukturEdit.penasehat_list" :key="index + 'k'">
                                  {{item.namaPengurus}};&nbsp;
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                      <b-row class="pt-3 pb-3 d-flex align-items-center">
                        <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">NIK Daftar Penasehat Organisasi</p></b-col
                        >:<b-col sm="8">
                          <b-row class="pb-2">
                            <b-col cols="10" class="pr-0 d-flex">
                              <div class="repeat-div">
                                <span v-for="(item,index) in strukturEdit.penasehat_list" :key="index + 'k'">
                                  {{item.identitas}};&nbsp;
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </section>

                    <section v-if="strukturEdit.pelindung_list.length > 0 && strukturEdit.pelindung_list[0].namaPengurus != ''">
                      <b-row class="pt-3 pb-3 d-flex align-items-center" >
                        <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Daftar Pelindung Organisasi</p></b-col
                        >:<b-col sm="8">
                          <b-row class="pb-2">
                            <b-col cols="10" class="pr-0 d-flex">
                              <div class="repeat-div">
                                <span v-for="(item,index) in strukturEdit.pelindung_list" :key="index + 'k'">
                                  {{item.namaPengurus}};&nbsp;
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                      <b-row class="pt-3 pb-3 d-flex align-items-center">
                        <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">NIK Daftar Pelindung Organisasi</p></b-col
                        >:<b-col sm="8">
                          <b-row class="pb-2">
                            <b-col cols="10" class="pr-0 d-flex">
                              <div class="repeat-div">
                                <span v-for="(item,index) in strukturEdit.pelindung_list" :key="index + 'k'">
                                  {{item.identitas}};&nbsp;
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </section>

                     <b-row class="mt-5">
                      <b-col class="d-flex justify-content-center pt-5 mt-5">
                        <button
                          class="mt-16 text-center button-back mr-5"
                          @click="navBack('struktur') == 'Batal' ? $router.push('/permohonan/form') : navLead('struktur') "
                        >
                          {{ navBack('struktur')}}
                        </button>
                        <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="navFwdAct('struktur')">
                          {{ navFwd('struktur')}}
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card-text>
          </div>

          <div v-if="formStatus.rekening && activeTab == 'rekening'" title="Rekening" style="background-color:white;margin-top:0px;padding:32px;">
             <b-row>
              <b-col class="d-flex justify-content-between">
                <span style="font-size:24px;font-weight:bold;">{{namaOrmasTitle}}</span>
                <!-- <b-button @click="$bvModal.show('modal-form-rekening')" variant="primary" size="sm"><v-icon dark>mdi-plus-box</v-icon> Tambah Rekening</b-button> -->
              </b-col>
            </b-row>
            <div class="table-responsive mt-5">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <!-- <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                    <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th> -->
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis Rekening</th>
                    <th style="width: 100px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, index) in formRekening">
                    <tr v-bind:key="index">
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ index + 1 }}</span
                        >
                      </td>
                      <!-- <td>
                        <b-form-input placeholder="Status Sekretariat"></b-form-input>
                      </td>
                      <td>
                        <b-form-input placeholder="Kabupaten/Kota"></b-form-input>
                      </td> -->
                      <td>
                        <b-form-input v-model="formRekening[index].noRekening" placeholder="Nomor Rekening"></b-form-input>
                      </td>
                      <td>
                        <b-form-input v-model="formRekening[index].namaBank" placeholder="Nama Bank"></b-form-input>
                      </td>
                       <td>
                        <b-form-input v-model="formRekening[index].namaPemilikRekening" placeholder="Pemilik Rekening"></b-form-input>
                      </td>
                       <td>
                        <b-form-select v-model="formRekening[index].idJenisRekening" >
                          <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                          <b-form-select-option :value="item.id" v-for="(item,index) in listRekening" :key="index">{{item.nama}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="d-flex justify-content-center">
                        <div class="button-plus" v-if="formRekening.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                        <div class="button-minus" v-if="index != 0 && formRekening.length - index == 1"><v-icon style="color:white;" small @click="formRekening.splice(-1)">mdi-minus</v-icon></div>
                        <div class="button-plus" v-if="formRekening.length - index == 1"><v-icon style="color:white;" small @click="formRekening.push({noRekening: '',namaBank: '',cabangBank: '',idJenisRekening: null,namaPemilikRekening: '',perubahanTipe: 0})">mdi-plus</v-icon></div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <b-row class="mt-5">
              <b-col class="d-flex justify-content-center pt-5 mt-5">
                <button
                  class="mt-16 text-center button-back mr-5"
                  @click="navBack('rekening') == 'Batal' ? $router.push('/permohonan/form') : navLead('rekening') "
                >
                  {{ navBack('rekening')}}
                </button>
                <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="navFwdAct('rekening')">
                  {{ navFwd('rekening')}}
                </b-button>
              </b-col>
            </b-row>
          </div>

          <div v-if="formStatus.cabang && activeTab == 'cabang'" title="Cabang Ormas" style="background-color:white;margin-top:0px;padding:32px;">
            <b-row class="mb-5">
              <b-col class="d-flex justify-content-between">
                <span style="font-size:24px;font-weight:bold;">{{namaOrmasTitle}}</span>
                <b-button @click="addCabang()" variant="primary" size="sm"><v-icon dark>mdi-plus-box</v-icon> Tambah Cabang</b-button>
              </b-col>
            </b-row>

            <div class="table-responsive mt-5">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                    <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                    <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                    <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                    <th style="width: 100px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in listCabang">
                    <tr v-bind:key="i">
                      <td>
                        <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      ">{{ i + 1}}</span>
                      </td>
                      <td>
                        <span class="
                        text-dark-75
                        text-left
                        font-weight-bolder
                        d-block
                        font-size-lg
                      ">{{ item.masterLokasiSekretariat.nama_lokasi}}</span>
                      </td>
                      <td>
                        <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      " v-if="item.alamatSekretariat">{{ item.alamatSekretariat.length > 30 ? item.alamatSekretariat.substring(0,30) + "..." : item.alamatSekretariat  }}</span>
                      </td>
                      <td>
                        <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      " v-if="item.wilayahKabupatenKota">{{ item.wilayahKabupatenKota.nama }}</span>
                      </td>
                      <td>
                        <span class="
                        text-dark-75
                        text-left
                        font-weight-bolder
                        d-block
                        font-size-lg
                      " v-if="item.wilayahProvinsi">{{ item.wilayahProvinsi.nama }}</span>
                      </td>
                      <td>
                        <b-button variant="danger" size="sm" style="padding:5px" class="mr-1" @click="deleteCabang(item.id)">
                          <v-icon style="color:white;padding:0px;" small>mdi-trash-can-outline</v-icon>
                        </b-button>
                        <b-button variant="primary" size="sm" style="padding:5px" class="mr-1">
                          <v-icon style="color:white;padding:0px;" @click="editCabang(item.id)" small>mdi-pencil</v-icon>
                        </b-button>
                        <!-- <b-button
                          variant="success"
                          size="sm"
                          @click="getDetailCabang(item.id)"
                        >
                          >> Detail
                        </b-button> -->
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>     
            <b-row class="mt-5">
              <b-col class="d-flex justify-content-center pt-5 mt-5">
                <button
                  class="mt-16 text-center button-back mr-5"
                  @click="navBack('cabang') == 'Batal' ? $router.push('/permohonan/form') : navLead('cabang') "
                >
                  {{ navBack('cabang')}}
                </button>
                <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="navFwdAct('cabang')">
                  {{ navFwd('cabang')}}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>


    <b-modal
      id="modal-form-struktur"
      ok-only
      centered
      size="md"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container class="text-center">
          <p style="font-size:24px; margin-top:20px;font-weight:600;">
            Pilih yang akan diubah
          </p>
          <b-row class="pt-3">
            <b-col cols="12">
              <div class="card-choice" @click="strukturEdit.pendiri = !strukturEdit.pendiri">
                <span>Pendiri Organisasi</span>
                <v-icon
                small
                :class="strukturEdit.pendiri ? 'pr-3 ic-green' : 'pr-3 ic-gray'"
              >
                mdi-check-circle
              </v-icon>
                
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-5">
            <b-col cols="12">
              <div class="card-choice" @click="strukturEdit.pembina = !strukturEdit.pembina" >
                  <span>Pembina Organisasi</span>
                <v-icon
                small
                :class="strukturEdit.pembina ? 'pr-3 ic-green' : 'pr-3 ic-gray'"
              >
                mdi-check-circle
              </v-icon>
              
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-5">
            <b-col cols="12">
              <div class="card-choice" @click="strukturEdit.penasehat = !strukturEdit.penasehat">
                  <span>Penasehat Organisasi</span>
                <v-icon
                small
                :class="strukturEdit.penasehat ? 'pr-3 ic-green' : 'pr-3 ic-gray'"
              >
                mdi-check-circle
              </v-icon>
              
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-5">
            <b-col cols="12">
              <div class="card-choice" @click="strukturEdit.pelindung = !strukturEdit.pelindung">
                  <span>Pelindung Organisasi</span>
                <v-icon
                small
                :class="strukturEdit.pelindung ? 'pr-3 ic-green' : 'pr-3 ic-gray'"
              >
                mdi-check-circle
              </v-icon>
              
              </div>
            </b-col>
          </b-row>
        </b-container>
        <div class="text-center">
          <b-button
            variant="outline-secondary"
            class="mt-16 text-center mr-5"
            style="width:185px;"
            @click="$bvModal.hide('modal-form-struktur')"
          >
            Batal
          </b-button>
          <b-button
            variant="primary"
            style="width:185px;"
            :disabled="!strukturEdit.pelindung && !strukturEdit.penasehat && !strukturEdit.pembina && !strukturEdit.pendiri"
            class="mt-16 text-center"
            @click="confirmChoice()"
          >
            Pilih
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-modal
      id="modal-form-struktur-pendiri"
      ok-only
      centered
      size="lg"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container class="text-center">
          <p style="font-size:24px; margin-top:20px;font-weight:600;">
            Pendiri Organisasi
          </p>
          <div class="table-responsive">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              "
            >
              <thead>
                <tr class="text-left">
                  <th style="min-width: 190px"  class="text-capitalize">Nama Pendiri</th>
                  <th style="min-width: 100px"  class="text-capitalize">NIK Pendiri</th>
                  <th style="min-width: 100px"  class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody style="background:#FAFAFA;">
                <template v-for="(item, index) in strukturEdit.pendiri_list">
                  <tr v-bind:key="index" >
                    <td>
                      <b-form-input v-model="strukturEdit.pendiri_list[index].namaPengurus" placeholder="Masukkan nama pendiri"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="strukturEdit.pendiri_list[index].identitas" placeholder="Masukkan NIK pendiri"></b-form-input>
                    </td>
                    <td class="d-flex justify-content-center">
                      <div class="button-plus" v-if="strukturEdit.pendiri_list.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                      <div class="button-minus" v-if="index != 0 && strukturEdit.pendiri_list.length - index == 1"><v-icon style="color:white;" small @click="strukturEdit.pendiri_list.splice(-1)">mdi-minus</v-icon></div>
                      <div class="button-plus" v-if="strukturEdit.pendiri_list.length - index == 1"><v-icon style="color:white;" small @click="strukturEdit.pendiri_list.push({namaPengurus: '', identitas: ''})">mdi-plus</v-icon></div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </b-container>
        <div class="text-center">
          <b-button
            variant="outline-secondary"
            class="mt-16 text-center mr-5"
            style="width:185px;"
            @click="$bvModal.hide('modal-form-struktur-pendiri')"
          >
            Batal
          </b-button>
          <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="navSFwdAct('pendiri')">
            {{ navSFwd('pendiri')}}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-form-struktur-pembina"
      ok-only
      centered
      size="lg"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container class="text-center">
          <p style="font-size:24px; margin-top:20px;font-weight:600;">
            Pembina Organisasi
          </p>
          <div class="table-responsive">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              "
            >
              <thead>
                <tr class="text-left">
                  <th style="min-width: 190px"  class="text-capitalize">Nama Pembina</th>
                  <th style="min-width: 100px"  class="text-capitalize">NIK Pembina</th>
                  <th style="min-width: 100px"  class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody style="background:#FAFAFA;">
                <template v-for="(item, index) in strukturEdit.pembina_list">
                  <tr v-bind:key="index" >
                    <td>
                      <b-form-input v-model="strukturEdit.pembina_list[index].namaPengurus" placeholder="Masukkan nama pembina"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="strukturEdit.pembina_list[index].identitas" placeholder="Masukkan NIK pembina"></b-form-input>
                    </td>
                    <td class="d-flex justify-content-center">
                      <div class="button-plus" v-if="strukturEdit.pembina_list.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                      <div class="button-minus" v-if="index != 0 && strukturEdit.pembina_list.length - index == 1"><v-icon style="color:white;" small @click="strukturEdit.pembina_list.splice(-1)">mdi-minus</v-icon></div>
                      <div class="button-plus" v-if="strukturEdit.pembina_list.length - index == 1"><v-icon style="color:white;" small @click="strukturEdit.pembina_list.push({namaPengurus: '', identitas: ''})">mdi-plus</v-icon></div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </b-container>
        <div class="text-center">
          <b-button
            variant="outline-secondary"
            class="mt-16 text-center mr-5"
            style="width:185px;"
            @click="navSBack('pembina') == 'Batal' ? navSLead('cancel', 'pembina') : navSLead('back','pembina') ">
                {{ navSBack('pembina')}}
          </b-button>
          <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="navSFwdAct('pembina')">
            {{ navSFwd('pembina')}}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-modal
      id="modal-form-struktur-penasehat"
      ok-only
      centered
      size="lg"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container class="text-center">
          <p style="font-size:24px; margin-top:20px;font-weight:600;">
            Penasehat Organisasi
          </p>
          <div class="table-responsive">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              "
            >
              <thead>
                <tr class="text-left">
                  <th style="min-width: 190px"  class="text-capitalize">Nama Penasehat</th>
                  <th style="min-width: 100px"  class="text-capitalize">NIK Penasehat</th>
                  <th style="min-width: 100px"  class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody style="background:#FAFAFA;">
                <template v-for="(item, index) in strukturEdit.penasehat_list">
                  <tr v-bind:key="index" >
                    <td>
                      <b-form-input v-model="strukturEdit.penasehat_list[index].namaPengurus" placeholder="Masukkan nama penasehat"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="strukturEdit.penasehat_list[index].identitas" placeholder="Masukkan NIK penasehat"></b-form-input>
                    </td>
                    <td class="d-flex justify-content-center">
                      <div class="button-plus" v-if="strukturEdit.penasehat_list.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                      <div class="button-minus" v-if="index != 0 && strukturEdit.penasehat_list.length - index == 1"><v-icon style="color:white;" small @click="strukturEdit.penasehat_list.splice(-1)">mdi-minus</v-icon></div>
                      <div class="button-plus" v-if="strukturEdit.penasehat_list.length - index == 1"><v-icon style="color:white;" small @click="strukturEdit.penasehat_list.push({namaPengurus: '', identitas: ''})">mdi-plus</v-icon></div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </b-container>
        <div class="text-center">
          <b-button
            variant="outline-secondary"
            class="mt-16 text-center mr-5"
            style="width:185px;"
            @click="navSBack('penasehat') == 'Batal' ? navSLead('cancel', 'penasehat') : navSLead('back', 'penasehat') ">
                {{ navSBack('penasehat')}}
          </b-button>
          <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="navSFwdAct('penasehat')">
            {{ navSFwd('penasehat')}}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-form-struktur-pelindung"
      ok-only
      centered
      size="lg"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container class="text-center">
          <p style="font-size:24px; margin-top:20px;font-weight:600;">
            Pelindung Organisasi
          </p>
          <div class="table-responsive">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              "
            >
              <thead>
                <tr class="text-left">
                  <th style="min-width: 190px"  class="text-capitalize">Nama Pelindung</th>
                  <th style="min-width: 100px"  class="text-capitalize">NIK Pelindung</th>
                  <th style="min-width: 100px"  class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody style="background:#FAFAFA;">
                <template v-for="(item, index) in strukturEdit.pelindung_list">
                  <tr v-bind:key="index" >
                    <td>
                      <b-form-input v-model="strukturEdit.pelindung_list[index].namaPengurus" placeholder="Masukkan nama pelindung"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="strukturEdit.pelindung_list[index].identitas" placeholder="Masukkan NIK pelindung"></b-form-input>
                    </td>
                    <td class="d-flex justify-content-center">
                      <div class="button-plus" v-if="strukturEdit.pelindung_list.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                      <div class="button-minus" v-if="index != 0 && strukturEdit.pelindung_list.length - index == 1"><v-icon style="color:white;" small @click="strukturEdit.pelindung_list.splice(-1)">mdi-minus</v-icon></div>
                      <div class="button-plus" v-if="strukturEdit.pelindung_list.length - index == 1"><v-icon style="color:white;" small @click="strukturEdit.pelindung_list.push({namaPengurus: '', identitas: ''})">mdi-plus</v-icon></div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </b-container>
        <div class="text-center">
          <b-button
            variant="outline-secondary"
            class="mt-16 text-center mr-5"
            style="width:185px;"
            @click="navSBack('pelindung') == 'Batal' ? navSLead('cancel', 'pelindung') : navSLead('back', 'pelindung') ">
                {{ navSBack('pelindung')}}
          </b-button>
          <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="navSFwdAct('pelindung')">
            {{ navSFwd('pelindung')}}
          </b-button>
        </div>
      </div>
    </b-modal>

     <b-modal
      id="modal-form-cabang"
      ok-only
      centered
      size="xl"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container>
          <b-row>
            <b-col cols="12">
              <b-row class="pt-5 mt-2">
                <b-col class="d-flex justify-content-between">
                  <b style="font-size:16px;">
                    Profil - Cabang Organisasi
                  </b>
                  <div>
                    <b-button @click="saveCabang()" variant="success" size="sm" class="mr-3">
                      <v-icon class="pr-3" style="color:white;">mdi-content-save</v-icon>
                      <span v-if="createMode">Simpan</span>
                      <span v-else>Ubah</span>
                    </b-button>
                    <b-button variant="outline-secondary" size="sm" @click="$bvModal.hide('modal-form-cabang')">
                      <v-icon class="pr-3">mdi-close</v-icon>
                      Tutup
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">{{ "Nama Ormas" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input v-model="$v.formCabang.nama_cabang.$model" :disabled="namaOrmasTitle != ''" :state="validateStateC('nama_cabang')" placeholder="Silahkan masukkan nama ormas"></b-form-input>
                </b-col>
              </b-row>
              <!-- <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">{{ "Singkatan" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input v-model="$v.formCabang.singkatan.$model" :state="validateStateC('singkatan')" placeholder="Silahkan masukkan singkatan"></b-form-input>
                </b-col>
              </b-row> -->
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">
                    {{ "Status Sekretariat" }}<span class="text-danger">*</span>
                  </p></b-col
                >:<b-col sm="6">
                   <b-form-select v-model="$v.formCabang.m_lokasi_sekretariat_id.$model" :state="validateStateC('m_lokasi_sekretariat_id')">
                      <b-form-select-option value="" disabled>Silahkan masukkan status sekretariat</b-form-select-option>
                      <b-form-select-option :value="item.id" v-for="(item,index) in listSekretariat" :key="index">{{item.namaLokasi}}</b-form-select-option>
                    </b-form-select>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">
                    {{ "Alamat sekretariat" }}<span class="text-danger">*</span>
                  </p></b-col
                >:<b-col sm="6">
                  <b-form-textarea
                  v-model="$v.formCabang.alamat_sekretariat.$model" :state="validateStateC('alamat_sekretariat')"
                    id="textarea"
                    placeholder="Silahkan masukkan alamat sekretariat"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-col>
              </b-row> 
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">{{ "Propinsi" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-select v-model="$v.selectedCabangPropinsi.$model" :state="validateStateCP('selectedCabangPropinsi')">
                    <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                    <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">{{ "Kabupaten/Kota" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-select v-model="$v.formCabang.kabupaten_kota_sekretariat.$model" :state="validateStateC('kabupaten_kota_sekretariat')">
                  <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten</b-form-select-option>
                    {{ listKota3 ? '' : ''}}
                      <b-form-select-option :value="item.kode" v-for="(item,index) in listKota3" :key="index">{{item.nama}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">{{ "Nomor Telepon" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input v-model="$v.formCabang.no_telp.$model" :state="validateStateC('no_telp')" placeholder="Silahkan masukkan nomor telepon"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">{{ "Nomor FAX" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input v-model="$v.formCabang.no_fax.$model" :state="validateStateC('no_fax')" placeholder="Silahkan masukkan nomor fax"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">{{ "NPWP" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input max="15" v-model="$v.formCabang.npwp.$model" :state="validateStateC('npwp')" placeholder="Silahkan masukkan NPWP"></b-form-input>
                </b-col>
              </b-row>
              

              <b-row class="pt-5 mt-2">
                <b-col>
                  <b style="font-size:16px;">
                    Info Pendirian Cabang - Cabang Organisasi
                  </b>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Nama Notaris</p></b-col>
                  <!-- <span class="text-danger">*</span> -->
                  <!-- :state="validateStateC('namaNotaris')" -->
                :<b-col sm="4">
                  <b-form-input v-model="$v.formCabang.namaNotaris.$model"  placeholder="Silahkan masukkan nama notaris"></b-form-input>
                    </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian</p></b-col
                >:<b-col sm="4">
                  <b-form-input v-model="$v.formCabang.nomorAkta.$model" placeholder="Silahkan masukkan nomor akta pendirian"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Tanggal AKTA Pendirian</p></b-col
                >:<b-col sm="4">
                  <b-form-datepicker v-model="$v.formCabang.tanggalAkta.$model" id="pendirian" class="mb-2"></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Nomor SK Susunan Pengurus<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-input v-model="$v.formCabang.nomorSuratPendaftaran.$model" :state="validateStateC('nomorSuratPendaftaran')" placeholder="Silahkan nomor SK susunan pengurus"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Tempat Pendirian<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-select v-model="$v.selectedCabangPropinsi2.$model" :state="validateStateCP2('selectedCabangPropinsi2')">
                    <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                    <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                  </b-form-select>
                </b-col>
                <b-col sm="4">
                  <b-form-select v-model="$v.formCabang.kabupatenKota.$model" :state="validateStateC('kabupatenKota')">
                    <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten</b-form-select-option>
                    {{ listKota4 ? '' : ''}}
                      <b-form-select-option :value="item.kode" v-for="(item,index) in listKota4" :key="index">{{item.nama}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Tanggal Pendirian<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-datepicker v-model="$v.formCabang.tanggalPendirian.$model" :state="validateStateC('tanggalPendirian')" id="pendiriancabang" class="mb-2"></b-form-datepicker>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="pt-5 mt-2 pb-5">
            <b-col>
              <b style="font-size:16px;">
                Struktur Organisasi - Cabang Organisasi<span class="text-danger">*</span>
              </b>
            </b-col>
          </b-row>

          <div class="table-responsive">
            <table
              class="
        table
        table-head-custom
        table-vertical-center
        table-head-bg
        table-bordered
      "
            >
              <thead>
                  <tr class="text-left">
                    <th style="width: 50px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                    <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                    <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                    <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                    <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                    <th style="width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                    <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in $v.formCabang.strukturOrganisasi.$each.$iter">
                    <tr v-bind:key="i">
                      <td class="d-flex justify-content-center">
                        <div  v-if="item.foto.$model == ''" @click="fileClicked('peng_foto_cab' + i)" style="background: #C4C4C4;width: 56px;height: 56px;border-radius: 10px;display:flex; align-items:center;justify-content:center;">
                          <v-icon>mdi-camera</v-icon>
                        </div>
                        <img v-if="item.foto.$model !== ''"
                          @click="fileClicked('peng_foto_cab' + i)"
                          :src="fileURL + item.foto.$model"
                          style="width: 56px;height: 56px;"
                          alt="abstract"
                          @error="replaceByDefault"
                        />
                      </td>
                      <td>
                          <b-form-input v-model="item.nama_pengurus.$model" :state="validateStateCS(i, 'nama_pengurus')" :placeholder="'Nama '+item.namaJabatan"></b-form-input>
                      </td>
                      <td>
                        <div class="d-flex justify-content-between align-items-center">
                        <b-form-datepicker :id="'mulaic'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        v-model="item.periode_mulai.$model" :state="validateStateCS(i, 'periode_mulai')" placeholder="Mulai" 
                        style="height:40px;width:130px;"></b-form-datepicker> - 
                        <b-form-datepicker :id="'akhirc'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        v-model="item.periode_selesai.$model" :state="validateStateCS(i, 'periode_selesai')" placeholder="Selesai" 
                        style="height:40px;width:130px;"></b-form-datepicker>
                        </div>
                      </td>
                      <td>
                        <b-form-select v-model="item.m_jabatan_id.$model" :state="validateStateCS(i, 'm_jabatan_id')">
                          <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                          <b-form-select-option :value="itm.id" v-for="(itm, ii) in listJabatan" :key="ii">{{itm.namaJabatan}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td>
                        <b-form-input v-model="item.identitas.$model" :state="validateStateCS(i, 'identitas')" placeholder="No Identitas"></b-form-input>
                      </td>
                      <td>
                        <b-form-datepicker :id="'erc'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        v-model="item.tanggal_lahir.$model" :state="validateStateCS(i, 'tanggal_lahir')" placeholder="TTL" 
                        style="height:40px;width:130px;" class="mb-2"></b-form-datepicker>
                      </td>
                      <td>
                        <b-form-input placeholder="No HP" v-model="item.no_hp.$model" :state="validateStateCS(i, 'no_hp')"></b-form-input>
                      </td>
                    </tr>
                  </template>
                </tbody>
            </table>
          </div>
          <b-row class="d-flex align-items-center">
            <b-col sm="3"
              ><p style="font-weight: 600; margin:0px;">Unggah SK Pengurus<span class="text-danger">*</span></p></b-col
            >:<b-col sm="6">
              <b-button v-if="formCabang.file_sk_pengurus == ''" variant="primary" size="sm" @click="fileClicked('skPengurusCab')"><v-icon>mdi-file-plus</v-icon> Unggah File Pengurus</b-button>
                    <a v-if="formCabang.file_sk_pengurus != ''" :href="fileURL + formCabang.file_sk_pengurus" target="_blank" type="button">
                    <b-button variant="success" size="sm">
                      Lihat File
                    </b-button>
                    </a>
                    <b-button v-if="formCabang.file_sk_pengurus != ''" @click="formCabang.file_sk_pengurus = ''" variant="danger" size="sm" class="ml-3">
                      <v-icon class="p-0">mdi-delete-outline</v-icon>
                    </b-button>
            </b-col>
          </b-row>

            <b-row class="pt-5 mt-2 pb-5">
            <b-col>
              <b style="font-size:16px;">
                Rekening - Cabang Organisasi<span class="text-danger">*</span>
              </b>
            </b-col>
          </b-row>

          <div class="table-responsive">
            <table class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-bordered
            ">
              <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis Rekening</th>
                    <th style="width: 100px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, index) in $v.formRekeningC.$each.$iter">
                    <tr v-bind:key="index">
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ index + 1 }}</span
                        >
                      </td>
                      <td>
                        <b-form-input v-model="item.no_rekening.$model" :state="validateStateRekC(index, 'no_rekening')" placeholder="Nomor Rekening"></b-form-input>
                      </td>
                      <td>
                        <b-form-input v-model="item.nama_bank.$model" :state="validateStateRekC(index, 'nama_bank')" placeholder="Nama Bank"></b-form-input>
                      </td>
                       <td>
                        <b-form-input v-model="item.nama_pemilik_rekening.$model" :state="validateStateRekC(index, 'nama_pemilik_rekening')" placeholder="Pemilik Rekening"></b-form-input>
                      </td>
                       <td>
                        <b-form-select v-model="item.id_jenis_rekening.$model" :state="validateStateRekC(index, 'id_jenis_rekening')">
                          <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                          <b-form-select-option :value="dd.id" v-for="(dd,o) in listRekening" :key="o">{{dd.nama}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="d-flex justify-content-center">
                        <div class="button-plus" v-if="formRekeningC.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                        <div class="button-minus" v-if="index != 0 && formRekeningC.length - index == 1"><v-icon style="color:white;" small @click="formRekeningC.splice(-1)">mdi-minus</v-icon></div>
                        <div class="button-plus" v-if="formRekeningC.length - index == 1"><v-icon style="color:white;" small @click="addRekC(index)">mdi-plus</v-icon></div>
                      </td>
                    </tr>
                  </template>
                </tbody>
            </table>
          </div>
        </b-container>
        
      </div>
    </b-modal>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required,email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";


export default {
  mixins: [validationMixin],
  name: "formpermohonan",
  data() {
    return {
      currentData: [],
      changedData: [],
      namaOrmasTitle: '',
      jenis_file: '',
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL + '/',
      file_logo: '',
      file_bendera: '',
      file_skt: '',
      file_npwp: '',
      pemilik_rekening: '',
      formStatus: {
        cabang: false,
        profile: false,
        rekening: false,
        structure: false
      },
      formArr: [],
      bidang_kegiatans: [''],
      badan_otonoms: [''],
      daftar_pendiri: [''],
      progress:0,
      daftar_pembinan: [''],
      daftar_penasehat: [''],
      daftar_pelindung: [''],
      rekening: [''],
      rekeningcabang: [''],
      activeTab: '',
      formProfil: {
          no_tlp: '',
          no_fax: '',
          email: '',
          m_ciri_khusus_id: '',
          wadahBerhimpun: '',
          badan_otonom: [{id: ''}]
      },
      formPendiri: {
        nama_notaris: '',
        no_akta_notaris: '',
        tanggal_akta_pendirian: '',
        propinsi: '',
        kabupaten: '',
        tanggal_pendirian: '',
        no_surat_permohonan: '',
        tgl_surat_permohonan: '',
      },
      formStruktur: {
        
      },
      formRekening: [{
        noRekening: '',
        namaBank: '',
        idJenisRekening: null,
        namaPemilikRekening: '',
      }],
      formCabang: {
        nama_cabang: '',
        no_telp: '',
        singkatan:'-',
        no_fax: '',
        alamat_sekretariat: '',
        npwp: '',
        tanggal_berdiri: '',
        kabupaten_kota_sekretariat: '',
        m_lokasi_sekretariat_id: '',
        file_sk_pengurus: '',

        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: '',
        kabupatenKota: '',
        tanggalPendirian: '',
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: '-',
        strukturOrganisasi: [
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          }
        ]
      },
      formRekeningC: [
        {
          no_rekening: '',
          nama_bank: '',
          // cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening:''  
        }
      ],
      vProfile: false,
      vPendiri: false,
      vStruktur: false,
      vRekening: false,
      vCabang: false,
      listSekretariat:[],
      listPropinsi: [],
      listCiriKhusus: [],
      listBidangKegiatan: [],
      listBadanOtonom: [],
      listJabatan: [],
      listRekening: [],
      listKota: [],
      listKota2: [],
      listKota3: [],
      listKota4: [],
      selectedPropinsi: '',
      selectedPendiriPropinsi: '',
      strukturEdit: {
        pendiri: false,
        pembina: false,
        penasehat: false,
        pelindung: false,
        pendiri_list: [],
        pembina_list: [],
        penasehat_list: [],
        pelindung_list: []
      },
      status_strukturEdit: [],
      selectedCabangPropinsi: '',
      selectedCabangPropinsi2: '',
      listCabang: [],
      createMode: true,
      idCabang: '',
    }
  },
  validations: {
    selectedPropinsi: {required},
    selectedPendiriPropinsi: {required},
    formProfil: {
          email: {
        email
      },
    },
    formCabang: {
        nama_cabang: {required},
        no_telp: {required},
        singkatan: {required},
        no_fax: {required},
        alamat_sekretariat: {required},
        npwp: {required},
        kabupaten_kota_sekretariat: {required},
        m_lokasi_sekretariat_id: {required},

        namaNotaris: {},
        nomorAkta: {},
        tanggalAkta: {},
        kabupatenKota: {required},
        tanggalPendirian: {required},
        nomorSuratPendaftaran: {required},
        strukturOrganisasi: {
          $each: {
            nama_pengurus: {required},
            periode_mulai: {required},
            periode_selesai: {required},
            m_jabatan_id: {required},
            identitas: {required},
            tanggal_lahir: {required},
            no_hp: {required},
            foto: {}
          }
        },
      },
      formRekeningC: {
        $each: {
          no_rekening: {required},
          nama_bank: {required},
          nama_pemilik_rekening: {required},
          id_jenis_rekening: {required}
        }
      },
      selectedCabangPropinsi: {required},
      selectedCabangPropinsi2: {required},
  },
  mounted() {
    this.namaOrmasTitle = JSON.parse(localStorage.getItem('profile')).dataOrmas.namaOrmas
    this.formStatus = JSON.parse(localStorage.getItem('form-permohonan'))
    this.formArr = JSON.parse(localStorage.getItem('permohonan-arr'))

    if(this.formStatus.profile) {this.activeTab = 'profil'}
    else if(this.formStatus.structure) {this.activeTab = 'struktur'}
    else if(this.formStatus.rekening) {this.activeTab = 'rekening'}
    else if(this.formStatus.cabang) {this.activeTab = 'cabang'}
    this.getDataOrmas()
    this.getCiriKhusus()
    this.getBadanOtonom()
    this.getJabatan()
    this.getJenisRekening()
    // this.getListCabang()
    this.getStatusSekretariat()
    this.getPropinsi()

  },
  watch:{
    selectedCabangPropinsi(to){
      this.getKota3(to)
    },
    selectedCabangPropinsi2(to){
      this.getKota4(to)
    }
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formProfil[name];
      return $dirty ? !$error : null;
    },
    validateStateFPBid2(index){
      const { $dirty, $error } = this.$v.formProfil.badan_otonom.$each.$iter[index];
      return $dirty ? !$error : null;
    },
    validateStateRek(index, name){
      const { $dirty, $error } = this.$v.formRekening.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateC(name) {
      const { $dirty, $error } = this.$v.formCabang[name];
      return $dirty ? !$error : null;
    },
    validateStateCS(index, name){
      const { $dirty, $error } = this.$v.formCabang.strukturOrganisasi.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateRekC(index, name){
      const { $dirty, $error } = this.$v.formRekeningC.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateCP2(){
      const { $dirty, $error } = this.$v.selectedCabangPropinsi2;
      return $dirty ? !$error : null;
    },
    validateStateCP(){
      const { $dirty, $error } = this.$v.selectedCabangPropinsi;
      return $dirty ? !$error : null;
    },
    addRek(){
      this.$v.formRekening.$touch();
      if (!this.$v.formRekening.$anyError) {
        // this.loading = false
        // return;\
        this.formRekening.push({no_rekening: '',nama_bank: '',cabang_bank: '',nama_pemilik_rekening: '',id_jenis_rekening: null})
      }
      
    },
    fileClicked(item){
      this.jenis_file = item
      setTimeout(() => {
        this.$refs.fileInput.click()  
      }, 500);
    },
    uploadFile(e){
      this.loadUpload = true
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.loadUpload = false
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadDocumentUmum', formData).then((res) =>{

        if(res.data.status != false){
          // "path": res.data.data.newFilePath,
          Swal.fire({
            title: "Upload File Berhasil",
            text: '',
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {
            if(this.jenis_file == 'logo') this.file_logo = res.data.data.newFilePath
            if(this.jenis_file == 'bendera') this.file_bendera = res.data.data.newFilePath
            if(this.jenis_file == 'skt') this.file_skt = res.data.data.newFilePath
            if(this.jenis_file == 'npwp') this.file_npwp = res.data.data.newFilePath
            for(var a = 0; a < 3; a++){
              if(this.jenis_file == 'peng_foto_cab' + a) this.formCabang.strukturOrganisasi[a].foto = res.data.data.newFilePath
            }
            if(this.jenis_file == 'skPengurusCab') this.formCabang.file_sk_pengurus = res.data.data.newFilePath
            this.$forceUpdate()
          })
        }
        this.$refs.fileInput.value=null;
      }).catch((err) =>{
        this.loadUpload = false
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.$refs.fileInput.value=null;
      })
    },
    addRekC(){
      this.$v.formRekeningC.$touch();
      if (!this.$v.formRekeningC.$anyError) {
        // this.loading = false
        // return;\
        this.formRekeningC.push({no_rekening: '',nama_bank: '',cabang_bank: '',nama_pemilik_rekening: '',id_jenis_rekening: null})
      }
      
    },
    deleteCabang(id){
      const context = this
      Swal.fire({
            title: "Anda yakin ingin menghapus cabang?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Hapus",
          }).then((result) => {
            if (result.isConfirmed) {
              context.$store.dispatch("deleteCabangPermohonan", [this.$route.query.id,id]).then((res) => {
                if(res.data.error.cabangOrmas == 'Data cabang ormas tidak ditemukan'){
                  Swal.fire({
                    title: "Cabang gagal dihapus",
                    text: "",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Lanjut",
                  }).then(() => {
                    context.getDataOrmas()
                  })
                }else{
                  Swal.fire({
                    title: "Cabang berhasil dihapus",
                    text: "",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Lanjut",
                  }).then(() => {
                    context.getDataOrmas()
                  })
                }
              })
            }
          });
    },
    saveCabang(){
      this.$v.formCabang.$touch();
      this.$v.formRekeningC.$touch();
      this.$v.selectedCabangPropinsi.$touch()
      this.$v.selectedCabangPropinsi2.$touch()
      if (this.$v.formCabang.$anyError || this.$v.selectedCabangPropinsi.$anyError || this.$v.selectedCabangPropinsi2.$anyError || this.$v.formRekeningC.$anyError) {
        // this.loading = false
        return;
      }
      
      var rek = []
      for(var a=0 ; a < this.formRekeningC.length; a++){
        if(this.createMode){
          rek.push({
            noRekening: this.formRekeningC[a].no_rekening,
            namaBank: this.formRekeningC[a].nama_bank,
            namaPemilikRekening: this.formRekeningC[a].nama_pemilik_rekening,
            idJenisRekening: parseInt(this.formRekeningC[a].id_jenis_rekening),
            cabangBank: '-',
          })
        }else {
          rek.push({
            noRekening: this.formRekeningC[a].no_rekening,
            namaBank: this.formRekeningC[a].nama_bank,
            namaPemilikRekening: this.formRekeningC[a].nama_pemilik_rekening,
            idJenisRekening: parseInt(this.formRekeningC[a].id_jenis_rekening),
            cabangBank: '-',
            cabangOrmasId: this.idCabang,
            // perubahanTipe: 0
          })
        }
        
      }
      var cab = []
      for(var s=0 ; s < this.formCabang.strukturOrganisasi.length; s++){
        var ddo = this.formCabang.strukturOrganisasi[s]
        cab.push({
          namaPengurus: ddo.nama_pengurus,
          periodeMulai: ddo.periode_mulai,
          periodeSelesai: ddo.periode_selesai,
          mJabatanId: ddo.m_jabatan_id,
          identitas: ddo.identitas,
          tempatLahir: '-',
          tanggalLahir: ddo.tanggal_lahir,
          noHp: ddo.no_hp,
          foto: ddo.foto
        })
      }
      var dataform = {}
      if(this.createMode){
        dataform = {
          "profil": {
            "namaCabang": this.formCabang.nama_cabang,
            "noTelp": this.formCabang.no_telp,
            "noFax": this.formCabang.no_fax,
            // "email": this.formProfil.email,
            "alamatSekretariat": this.formCabang.alamat_sekretariat,
            "npwp": this.formCabang.npwp,
            "tanggalBerdiri": this.formCabang.tanggalPendirian + 'T00:00:00.000Z',
            "provinsiSekretariat": this.selectedCabangPropinsi,
            "kabupatenKotaSekretariat": this.formCabang.kabupaten_kota_sekretariat,
            "mLokasiSekretariatId": this.formCabang.m_lokasi_sekretariat_id,
            "file_sk_pengurus": this.formCabang.file_sk_pengurus
          },
          "infoPendirian": {
            "namaNotaris": this.formCabang.namaNotaris,
            "nomorAkta": this.formCabang.nomorAkta,
            "tanggalAkta": this.formCabang.tanggalAkta + 'T00:00:00.000Z',
            "kabupatenKota": this.formCabang.kabupatenKota,
            "tanggalPendirian": this.formCabang.tanggalPendirian + 'T00:00:00.000Z',
            "nomorSuratPendaftaran": this.formCabang.nomorSuratPendaftaran,
            // "tanggalSuratPendaftaran": this.formCabang.tanggalAkta + 'T00:00:00.000Z',
            "provinsi": this.selectedCabangPropinsi2
          },
          "strukturOrganisasi": cab,
          "rekening": rek
        }
      } else {
        dataform = {
          "profil": {
              "namaCabang": this.formCabang.nama_cabang,
              "noTelp": this.formCabang.no_telp,
              "noFax": this.formCabang.no_fax,
              "alamatSekretariat": this.formCabang.alamat_sekretariat,
              "npwp": this.formCabang.npwp,
              "tanggalBerdiri": this.formCabang.tanggalPendirian + 'T00:00:00.000Z',
              "provinsiSekretariat": this.selectedCabangPropinsi,
              "kabupatenKotaSekretariat": this.formCabang.kabupaten_kota_sekretariat,
              "mLokasiSekretariatId": this.formCabang.m_lokasi_sekretariat_id,

              "m_lokasi_sekretariat_id": this.formCabang.m_lokasi_sekretariat_id,
              "provinsi_sekretariat": this.selectedCabangPropinsi,
              "kabupaten_kota_sekretariat":  this.formCabang.kabupaten_kota_sekretariat,
              "file_sk_pengurus": this.formCabang.file_sk_pengurus
            },
            "infoPendirian": {
              "namaNotaris": this.formCabang.namaNotaris,
              "nomorAkta": this.formCabang.nomorAkta,
              "tanggalAkta": this.formCabang.tanggalAkta + 'T00:00:00.000Z',
              "kabupatenKota": this.formCabang.kabupatenKota,
              "tanggalPendirian": this.formCabang.tanggalPendirian + 'T00:00:00.000Z',
              "nomorSuratPendaftaran": this.formCabang.nomorSuratPendaftaran,
              // "tanggalSuratPendaftaran": this.formCabang.tanggalAkta + 'T00:00:00.000Z',
              "provinsi": this.selectedCabangPropinsi2
            },
            "strukturOrganisasi": cab,
            "rekening": rek
          }
      }

      var submittingTo = ''
      if(this.formCabang.namaNotaris == '') delete dataform.infoPendirian.namaNotaris
      if(this.formCabang.nomorAkta == '') delete dataform.infoPendirian.nomorAkta
      if(this.formCabang.tanggalAkta == '') delete dataform.infoPendirian.tanggalAkta

      if(this.createMode){
        submittingTo = 'submitPermohonanCabang'
        this.$store
        .dispatch(submittingTo, [dataform, this.$route.query.id])
        .then((res) => {
          this.getDataOrmas()
          this.$bvModal.hide('modal-form-cabang')
          window.scrollTo(0,0);
          this.resetFormCabang()
           if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: bb != '' ? "success" : "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            Swal.fire({
              title: res.data.message,
              text: '',
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          }
        }).catch(() =>{
          Swal.fire({
              title: "Proses tambah data gagal",
              text: '',
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
        })
      } else{ 
        submittingTo = 'submitPermohonanCabangPatch'
        this.$store
        .dispatch(submittingTo, [dataform, this.idCabang, this.$route.query.id])
        .then((res) => {
          this.getDataOrmas()
          this.$bvModal.hide('modal-form-cabang')
          window.scrollTo(0,0);
          this.resetFormCabang()
          if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: bb != '' ? "success" : "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            Swal.fire({
              title: res.data.message,
              text: '',
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          }
        }).catch(() =>{
          Swal.fire({
              title: "Proses tambah data gagal",
              text: '',
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
        })
        
      }
      
    },
    resetFormCabang(){
      this.formCabang = {
        nama_cabang: '',
        no_telp: '',
        singkatan:'-',
        no_fax: '',
        alamat_sekretariat: '',
        npwp: '',
        tanggal_berdiri: '',
        kabupaten_kota_sekretariat: '',
        m_lokasi_sekretariat_id: '',
        file_sk_pengurus: '',

        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: '',
        kabupatenKota: '',
        tanggalPendirian: '',
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: '-',
        strukturOrganisasi: [
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          }
        ],
      }
      this.formRekeningC = [
        {
          no_rekening: '',
          nama_bank: '',
          // cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening:''  
        }
      ]

      this.selectedCabangPropinsi = ''
      this.selectedCabangPropinsi2 = ''
    },
    fillFormCabang(d, id){
      var peng = []

      if(d.pengurusOrmas.length == 0) {
        for(var oo = 0; oo < 3; oo++){
          peng.push(
            {
                nama_pengurus: '',
                periode_mulai: '',
                periode_selesai: '',
                m_jabatan_id: null,
                identitas: '',
                tempat_lahir: '-',
                tanggal_lahir: '',
                no_hp: ''
              }
          ) 
        }
      } else {
        for(var b=0 ; b < d.pengurusOrmas.length; b++){
          var dd = d.pengurusOrmas[b]
          peng.push(
            {
              nama_pengurus: dd.namaPengurus,
              periode_mulai: dd.periodeMulai.substring(0, 10),
              periode_selesai: dd.periodeSelesai.substring(0, 10),
              m_jabatan_id: dd.mJabatanId,
              identitas: dd.identitas,
              tempat_lahir: dd.tempatLahir,
              tanggal_lahir: dd.tanggalLahir.substring(0, 10),
              no_hp: dd.noHp,
              foto: dd.foto
            }
          )
        }
      }

      this.idCabang = id
      this.formCabang = {
        nama_cabang: d.cabangOrmas.namaCabang,
        no_telp: d.cabangOrmas.noTelp,
        singkatan:'-',
        no_fax: d.cabangOrmas.noFax,
        alamat_sekretariat: d.cabangOrmas.alamatSekretariat,
        npwp: d.cabangOrmas.npwp,
        tanggal_berdiri: d.cabangOrmas.tanggalBerdiri.substring(0, 10),
        kabupaten_kota_sekretariat: d.cabangOrmas.kabupaten_kota_sekretariat,
        m_lokasi_sekretariat_id: d.cabangOrmas.m_lokasi_sekretariat_id,

        namaNotaris: d.infoPendirian.namaNotaris,
        nomorAkta: d.infoPendirian.nomorAkta,
        tanggalAkta: d.infoPendirian.tanggalAkta.substring(0, 10),
        kabupatenKota: d.infoPendirian.kabupatenKota,
        tanggalPendirian: d.infoPendirian.tanggalPendirian.substring(0, 10),
        nomorSuratPendaftaran: d.infoPendirian.nomorSuratPendaftaran,
        tanggalSuratPendaftaran: d.infoPendirian.tanggalSuratPendaftaran.substring(0, 10),
        strukturOrganisasi: peng,
      }
      
      this.selectedCabangPropinsi = d.cabangOrmas.wilayahProvinsi.kode
      this.selectedCabangPropinsi2 = d.infoPendirian.provinsi

      var rek = []
      for(var a=0 ; a < d.rekeningOrmas.length; a++){
        rek.push({
          no_rekening: d.rekeningOrmas[a].noRekening,
          nama_bank: d.rekeningOrmas[a].namaBank,
          nama_pemilik_rekening: d.rekeningOrmas[a].namaPemilikRekening,
          id_jenis_rekening: d.rekeningOrmas[a].id_jenis_rekening
        })
      }
      if(rek.length == 0) {
        rek.push({
          no_rekening: '',
          nama_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening: ''
        })
      }
      this.formRekeningC = rek

      
    },
    addCabang(){
      this.resetFormCabang()
      this.$v.$reset()
      this.formCabang.nama_cabang = this.namaOrmasTitle
      this.createMode = true
      this.$bvModal.show('modal-form-cabang')
    },
    editCabang(id){
      this.$v.$reset()
      this.$store
        .dispatch('getDetailCabangPermohonan',[this.$route.query.id,id])
        .then((res) => {
          this.resetFormCabang()
          this.createMode = false
          this.$bvModal.show('modal-form-cabang')    
          this.resetFormCabang()
          this.fillFormCabang(res.data.data, id)
        })
    },
    getDataOrmas(){

      this.$store
      .dispatch("getDataPerubahan", this.$route.query.id)
      .then((res) => {
        var da = res.data.data
        this.$nextTick(() => {
            var bd = []
            if(da.berhimpunOtonom.badanOtonom.length != 0){
              for(var ii = 0; ii < da.berhimpunOtonom.badanOtonom.length; ii++){
                bd.push({id: da.berhimpunOtonom.badanOtonom[ii].afiliasiOrmasId})
              }
            } else {
              bd.push({id: ''})
            }
            if(da.profil){
              this.formProfil = {
                no_tlp: da.profil.noTelp,
                no_fax: da.profil.noFax,
                email: da.profil.email,
                m_ciri_khusus_id: da.profil.masterCiriKhusus.id,
                wadahBerhimpun: da.berhimpunOtonom.wadahBerhimpun ? da.berhimpunOtonom.wadahBerhimpun.afiliasiOrmasId : '',
                badan_otonom: bd
              }
            }
            for(var ab = 0; ab < da.strukturOrganisasi.length; ab++){
              var SO = da.strukturOrganisasi[ab]
              if(SO.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri') {
                this.strukturEdit.pendiri_list.push({
                  namaPengurus: SO.namaPengurus,
                  identitas: SO.identitas
                })
              }
              if(SO.masterKategoriPengurus.kode_kategori_pengurus == 'pembina') {
                this.strukturEdit.pembina_list.push({
                  namaPengurus: SO.namaPengurus,
                  identitas: SO.identitas
                })
              }
              if(SO.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat') {
                this.strukturEdit.penasehat_list.push({
                  namaPengurus: SO.namaPengurus,
                  identitas: SO.identitas
                })
              }
              if(SO.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung') {
                this.strukturEdit.pelindung_list.push({
                  namaPengurus: SO.namaPengurus,
                  identitas: SO.identitas
                })
              }
            }
            // this.formProfil.no_telp = base.noTelp
            // this.formProfil.no_fax = base.noFax
            // this.formProfil.email = base.email
            if(this.strukturEdit.pendiri_list.length == 0) this.strukturEdit.pendiri_list.push({namaPengurus: '',identitas: ''})
            if(this.strukturEdit.pembina_list.length == 0) this.strukturEdit.pembina_list.push({namaPengurus: '',identitas: ''})
            if(this.strukturEdit.penasehat_list.length == 0) this.strukturEdit.penasehat_list.push({namaPengurus: '',identitas: ''})
            if(this.strukturEdit.pelindung_list.length == 0) this.strukturEdit.pelindung_list.push({namaPengurus: '',identitas: ''})
          // }
          this.formRekening = [{
                  noRekening: '',
                  namaBank: '',
                  namaPemilikRekening: '',
                  idJenisRekening: ''
                }]
          var rekc = []

            for(var i = 0; i < da.rekening.length; i++){
              rekc.push(
                {
                  noRekening: da.rekening[i].noRekening,
                  namaBank: da.rekening[i].namaBank,
                  namaPemilikRekening: da.rekening[i].namaPemilikRekening,
                  idJenisRekening: da.rekening[i].idJenisRekening
                }
              )
            }
          
          this.formRekening = rekc
          this.listCabang = []
          for(var s = 0;s < da.cabangOrmas.length; s++){
            this.listCabang.push(da.cabangOrmas[s])
          }       
        })
      })
    },
    navFwdAct(at){
      for(var i = 0; i < this.formArr.length; i++){
        if(this.formArr[i] === at){
          if(this.formArr[i + 1]) {
            if(at == 'profil') this.cekProfile(this.formArr[i + 1])
            if(at == 'struktur') this.cekStruktur(this.formArr[i + 1])
            if(at == 'rekening') this.cekRekening(this.formArr[i + 1])
            if(at == 'cabang') this.cekCabang(this.formArr[i + 1])
          } else {
            if(at == 'profil') this.cekProfile(this.formArr[i + 1])
            if(at == 'struktur') this.cekStruktur(this.formArr[i + 1])
            if(at == 'rekening') this.cekRekening(this.formArr[i + 1])
            if(at == 'cabang') this.cekCabang(this.formArr[i + 1])
            this.finish()
          }
        }
      }
      // window.scrollTo(0,0);
    },
    navFwd(at){
      for(var i = 0; i < this.formArr.length; i++){
        if(this.formArr[i] === at){
          if(this.formArr[i + 1]) {
            return 'Lanjut'
          } else {
            return 'Ajukan'
          }
        }
         
      }
    },
    navBack(at) {
      // var b = null
      // var i = 0
      for(var i = 0; i < this.formArr.length; i++){
        if(this.formArr[i] === at){
          if(this.formArr[i - 1]) {
            return 'Kembali'
          } else {
            return 'Batal'
          }
        }
         
      }
    },
    navLead(at) {
      // var i = 0
      for(var i = 0; i < this.formArr.length; i++){
        if(this.formArr[i] === at){
          this.activeTab = this.formArr[i - 1]
        }
      }
      window.scrollTo(0,0);
    },
    cekProfile(to){
      this.$v.formProfil.$touch();
      if (this.$v.formProfil.$anyError) {
        return
      }

      var bon = []
      for(var a = 0; a < this.formProfil.badan_otonom.length; a++){
        bon.push(this.formProfil.badan_otonom[a].id)
      }
      var dataform = {
            "dataOrmas": {
              "mCiriKhususId": this.formProfil.m_ciri_khusus_id,
              "email": this.formProfil.email,
              "noTelp": this.formProfil.no_tlp,
              "noFax": this.formProfil.no_fax,
              "file_logo": this.file_logo,
              "file_bendera": this.file_bendera
            },
            "wadahBerhimpun": this.formProfil.wadahBerhimpun,
            "badanOtonom": bon
          }
      
      this.$store
        .dispatch("submitPerubahanProfil", [dataform, this.$route.query.id])
        .then((/*res*/) => {
          if(!this.vProfile) {
            this.vProfile = true
            this.progress = this.progress + 18
            this.activeTab = to
            window.scrollTo(0,0);
          }
        })
    },
    cekStruktur(to){
      if(!this.vStruktur) {
        this.vStruktur = true
        this.progress = this.progress + 22
      }
      this.activeTab = to
      window.scrollTo(0,0);
    },
    cekRekening(to){
      var form= []
      for(var i = 0; i < this.formRekening.length; i++){
        form.push({
          "noRekening": this.formRekening[i].noRekening,
          "namaBank": this.formRekening[i].namaBank,
          // "cabangBank": "string",
          "idJenisRekening": this.formRekening[i].idJenisRekening,
          "namaPemilikRekening": this.formRekening[i].namaPemilikRekening,
          // "perubahanTipe": 0
        })
      }
      // var dataform = this.formRekening
      var dataform = form

      this.$store
        .dispatch("submitPerubahanRekening", [dataform, this.$route.query.id])
        .then((/*res*/) => {
          if(!this.vRekening) {
            this.vRekening = true
            this.progress = this.progress + 17
          }
          this.activeTab = to
          window.scrollTo(0,0);
        })
    },
    cekCabang(to){
      if(!this.vCabang) {
        this.vCabang = true
        this.progress = this.progress + 25
      }
      this.activeTab = to
      window.scrollTo(0,0);
    },
    finish(){
      this.$store
        .dispatch('submitPermohonan', this.$route.query.id)
        .then((/*res*/) => {
          this.$router.push('/permohonan')
        })
    },
    back(){
      this.activeTab = this.activeTab - 1
      window.scrollTo(0,0);
    },
    
    confirmChoice(){
      this.status_strukturEdit = []
      if(this.strukturEdit.pendiri) this.status_strukturEdit.push('pendiri')
      if(this.strukturEdit.pembina) this.status_strukturEdit.push('pembina')
      if(this.strukturEdit.penasehat) this.status_strukturEdit.push('penasehat')
      if(this.strukturEdit.pelindung) this.status_strukturEdit.push('pelindung')
      this.$bvModal.hide('modal-form-struktur')
      this.$bvModal.show('modal-form-struktur-' + this.status_strukturEdit[0])
    },
    navSFwdAct(at){
      for(var i = 0; i < this.status_strukturEdit.length; i++){
        if(this.status_strukturEdit[i] === at){
          if(this.status_strukturEdit[i + 1]) {
            if(at == 'pendiri') this.cekPendiri(this.status_strukturEdit[i],this.status_strukturEdit[i + 1])
            if(at == 'pembina') this.cekPembina(this.status_strukturEdit[i],this.status_strukturEdit[i + 1])
            if(at == 'penasehat') this.cekPenasehat(this.status_strukturEdit[i],this.status_strukturEdit[i + 1])
            if(at == 'pelindung') this.cekPelindung(this.status_strukturEdit[i],this.status_strukturEdit[i + 1])
          } else {
            this.finishStruktur(this.status_strukturEdit[i])
          }
        }
      }
      window.scrollTo(0,0);
    },
    navSFwd(at){
      for(var i = 0; i < this.status_strukturEdit.length; i++){
        if(this.status_strukturEdit[i] === at){
          if(this.status_strukturEdit[i + 1]) {
            return 'Lanjut'
          } else {
            return 'Ajukan'
          }
        }
         
      }
    },
    navSBack(at) {
      for(var i = 0; i < this.status_strukturEdit.length; i++){
        if(this.status_strukturEdit[i] === at){
          if(this.status_strukturEdit[i - 1]) {
            return 'Kembali'
          } else {
            return 'Batal'
          }
        }
         
      }
    },
    navSLead(st, at) {
      // var i = 0
      for(var i = 0; i < this.status_strukturEdit.length; i++){
        if(this.status_strukturEdit[i] === at){
          if(st == 'cancel'){
            this.$bvModal.hide('modal-form-struktur-' + at)
          } else {
            this.$bvModal.hide('modal-form-struktur-' + at)
            this.$bvModal.show('modal-form-struktur-' + this.status_strukturEdit[i - 1])
          }
        }
      }
      window.scrollTo(0,0);
    },

    cekPendiri(at,to){
      this.$bvModal.hide('modal-form-struktur-' + at)
      this.$bvModal.show('modal-form-struktur-' + to)
    },
    cekPembina(at,to){
      this.$bvModal.hide('modal-form-struktur-' + at)
      this.$bvModal.show('modal-form-struktur-' + to)
    },
    cekPenasehat(at,to){
      this.$bvModal.hide('modal-form-struktur-' + at)
      this.$bvModal.show('modal-form-struktur-' + to)
    },
    cekPelindung(at,to){
      this.$bvModal.hide('modal-form-struktur-' + at)
      this.$bvModal.show('modal-form-struktur-' + to)
    },
    finishStruktur(at){
      var dataform = {
        "pengurus": {
          "pendiri": this.strukturEdit.pendiri_list,
          "pembina": this.strukturEdit.pembina_list,
          "penasehat": this.strukturEdit.penasehat_list,
          "pelindung": this.strukturEdit.pelindung_list
        }
      }
      this.strukturEdit.pendiri = false
      this.strukturEdit.pembina = false
      this.strukturEdit.penasehat = false
      this.strukturEdit.pelindung = false
      this.$store
        .dispatch("submitPerubahanStruktur",  [dataform, this.$route.query.id])
        .then((/*res*/) => {
          this.$bvModal.hide('modal-form-struktur-' + at)
        })
    },
    getCiriKhusus(){
      this.$store
        .dispatch("getCiriKhusus")
        .then((res) => {
           this.$nextTick(() => {
            this.listCiriKhusus = res.data
          })
          })
    },

    getBadanOtonom(){
      this.$store
        .dispatch("getBadanOtonom")
        .then((res) => {
           this.$nextTick(() => {
            this.listBadanOtonom = res.data
          })
          })
    },
    getJabatan(){
      this.$store
      .dispatch("getJabatan")
      .then((res) => {
          this.$nextTick(() => {
          this.listJabatan = res.data
        })
        })
    },
    getJenisRekening(){
      this.$store
        .dispatch("getJenisRekening")
        .then((res) => {
           this.$nextTick(() => {
            this.listRekening = res.data
          })
          })
    },
    getPropinsi(){
      this.$store
        .dispatch("getPropinsi")
        .then((res) => {
          
           this.$nextTick(() => {
            this.listPropinsi = res.data.data
          })
          })
    },
    getStatusSekretariat(){
      this.$store
        .dispatch("getStatusSekretariatOrmas")
        .then((res) => {
          
          this.$nextTick(() => {
            this.listSekretariat = res.data
          })
          })
    },
    getKota3(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota3 = res.data.data
          })
          })
    },
    getKota4(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota4 = res.data.data
          })
          })
    },
  },
  computed: {
    
  }
};
</script>

<style>
.ic-gray {
  color:gray !important;font-size:20px;
}
.ic-green {
  color:#339933 !important;font-size:20px;
}
.nav.nav-pills .nav-link {
  color: #053A69;
  font-weight: bold;
}
.button-plus {
  background:#339933;cursor:pointer; padding:5px; border-radius:50%;width:30px; height:30px;text-align:center; margin-left:5px;

}
.button-minus {
  background:#FF4433;cursor:pointer; padding:5px; border-radius:50%;width:30px; height:30px;text-align:center; margin-left:5px;
}
.nav.nav-pills .nav-item {
    margin-right: 10px;
    margin-left: 10px;
    max-width:230px !important;
}

.check-tab {
  background-color: rgb(229, 229, 229);
  border-radius: 50%;
  padding: 1px;
  position: absolute;
  top: -7px;
  right: -5px;
  width:25px;
}

#tab-form .card-header {
  display:none;
}

.header-row {
  background-color:#E5E5E5;border-top-left-radius: 15px;
    border-top-right-radius: 15px;padding: 2rem 2.25rem;
    margin-bottom: 0; margin-left:2px; margin-right:2px;
    border-bottom: 1px solid #EBEDF3;
}

#tabs.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-primary:focus:not(.btn-text), .btn.btn-primary.focus:not(.btn-text) {
    color: #FFFFFF;
    background-color: none !important;
    border-color: none !important;
}

#tabs {
  max-width:230px !important;
  position: relative;
}

.card-choice {
  cursor:pointer;box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.15);border-radius: 10px;display:flex; justify-content:space-between;padding:16px 24px 16px 24px;
}

.form-control.is-invalid{
  padding-right: 0px !important;
}
.form-control.is-valid{
  padding-right: 0px !important;
}

.repeat-div {
  height: 35px;border: 1px solid rgba(0, 0, 0, 0.6);border-radius: 5px;width:100%;display: flex;align-items: center;padding: 5px;
}
</style>

